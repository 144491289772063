.header {
  padding-top: 32px;
  background-color: rgba(217, 217, 217, 0.19);
}
.headerInner {
  width: 95%;
  margin: 0 auto;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 11.53px;
  cursor: pointer;
}
.logoWrapper h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 21.519px;
  line-height: 32px;
  color: #000000;
}
.logoWrapper h6 span {
  font-weight: 400;
  font-size: 21.519px;
  line-height: 32px;
  color: #72039d;
}
.rightItemsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 15px;
}
.bubble {
  width: 42px;
  height: 39px;
  background-color: rgba(217, 217, 217, 0.19);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.themeActive {
  background-color: #f1e1f8;
}
.langActive {
  background-color: #e1f1f8;
}
.vrLine {
  width: 1px;
  background: rgba(0, 0, 0, 0.3);
  height: 36px;
  margin-left: 4px;
}
.bubble img {
  width: 24px;
  height: 24px;
}
.bubble h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.signUpBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
  height: 39px;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  border-radius: 10px;
  border: none;
  padding: 0 16px;
  margin-left: 4px;
}
.signUpBtn:hover {
  background: linear-gradient(-87.61deg, #9f69b4 7.71%, #763092 93.23%);
}
.loginBtn {
  background: transparent;
  border: none;
}
.loginBtn span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.hamburger {
  display: none;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 39px;
  background: rgba(217, 217, 217, 0.19);
  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.hamburger i {
  color: #000;
}
.rightItemsWrapperMobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }
  .rightItemsWrapper {
    display: none;
  }
  .rightItemsWrapperMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px 0;
  }
  .bubble {
    width: 100%;
  }
}
