.hero {
  padding: 122px 0 0;
  background-color: rgba(217, 217, 217, 0.19);
}
.heroInner {
  width: 92%;
  margin: 0 auto;
}
.heroWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left {
  max-width: 595px;
  width: 100%;
}
.left h1 {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #000000;
  margin-bottom: 19px !important;
}
.left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16.5455px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 35px;
  max-width: 458px;
  width: 100%;
}
.signUpBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
  height: 39px;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  border-radius: 10px;
  border: none;
  padding: 0 16px;
  margin-left: 4px;
}
.signUpBtn:hover {
  background: linear-gradient(-87.61deg, #9f69b4 7.71%, #763092 93.23%);
}
.right {
  max-width: 416.93px;
  width: 100%;
  margin: 0 auto -22px;
  position: relative;
}
.right .mainImg {
  width: 100%;
}
.right .coinAbsImg {
  position: absolute;
  left: 10%;
  bottom: 0;
  width: 35%;
  /* transform: rotateY(45deg); */
  animation: rotateAnimation 5s linear infinite;
}
@keyframes rotateAnimation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@media screen and (max-width: 768px) {
  .heroWrapper {
    flex-direction: column;
  }
  .hero {
    padding: 54px 0 0;
  }
  .left {
    margin: auto;
  }
  .left p {
    margin-bottom: 15px;
  }
  .right {
    margin: 20px auto -22px;
  }
}
@media screen and (max-width: 600px) {
  .left h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .left p {
    font-size: 14px;
    line-height: 16px;
  }
  .heroWrapper {
    align-items: center;
  }
}
